




import { computed, defineComponent } from '@nuxtjs/composition-api';
import { createWidgetMixin } from 'vue-instantsearch';
import { connectRatingMenu } from 'instantsearch.js/es/connectors';

export default defineComponent({
  name: 'VirtualRatingMenu',
  mixins: [
    createWidgetMixin({ connector: connectRatingMenu })
  ],
  props: {
    attribute: {
      type: String,
      required: true
    },
    max: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const widgetParams = computed(() => {
      return { attribute: props.attribute, max: props.max };
    });

    return {
      widgetParams
    };
  }
});
