





import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductAnchorTitle',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
});
