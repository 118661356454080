export const productFilterForAddingToCart = (product) => (
  (product?.id)
    ? {
      id: product.id,
      images: product.images,
      assets: product.assets,
      attributesRaw: [],
      attributes: product.attributes,
      attributeList: product.attributeList,
      sku: product.sku
    }
    : product
);
