import type { ProductReview, CustomFieldsKeys } from '~/types/product/attribute/ProductReview';
export const getTranslatedCustomField = ({
  review,
  field,
  activeLocale
}: {
  review: ProductReview;
  field: CustomFieldsKeys;
  activeLocale: string;
}): string => {
  return review.custom?.[field]?.[0]?.value?.[activeLocale] || '';
};
