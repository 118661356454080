import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import { defaultVideoLanguageAndCountry } from '~/constants/language';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import getProductAttribute from '~/helpers/product/getProductAttribute/getProductAttribute';
import { ProductVideo } from '~/types/product/ProductVideo';

export const getProductVideo = (product: ProductVariant, languageAndCountry: string): ProductVideo | null => {
  const videoId = getProductAttribute(product, PRODUCT_ATTRIBUTES.VIDEO, languageAndCountry) ||
    getProductAttribute(product, PRODUCT_ATTRIBUTES.VIDEO, defaultVideoLanguageAndCountry);

  if (!videoId) {
    return null;
  }

  const link = `https://www.youtube.com/embed/${videoId}`;
  const thumbnail = `https://img.youtube.com/vi/${videoId}/0.jpg`;

  return {
    link,
    thumbnail
  };
};
