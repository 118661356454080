


























import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent } from '@nuxtjs/composition-api';
import VolumeDiscountRow from './VolumeDiscountRow.vue';
import { LinkType } from '~/types/components/Link/LinkType';
import { VolumeDiscount } from '~/types/volumeDiscounts';

export default defineComponent({
  name: 'VolumeDiscount',
  components: {
    VolumeDiscountRow
  },
  props: {
    finalPrice: {
      type: Number,
      required: true
    },
    volumeDiscounts: {
      type: Array as PropType<VolumeDiscount[]>,
      default: () => []
    }
  },
  setup() {
    return {
      LinkType
    };
  }
});

