import { Logger } from '@vue-storefront/core';
import type { HitResultItem } from 'vue-instantsearch';
import { getEnergyRating } from '~/helpers/product/energyRating';
import { ProductCardItem } from '~/types/product/ProductCardItem';

export const convertAlgoliaProductsToCardProducts = (products: HitResultItem[]): ProductCardItem[] => {
  return products?.reduce((productCardItems: Array<ProductCardItem>, product: HitResultItem) => {
    if (product.objectID && product.name && product.price) {
      productCardItems.push(
        {
          ...product,
          price: product.standardPrice || product.price,
          discountPrice: product.standardPrice ? product.price : undefined,
          hasDimensions: product.width !== null && product.height !== null && product.length !== null,
          energyRatingDetails: getEnergyRating({ item: product })
        }
      );
    } else {
      Logger.error(
        'convertAlgoliaProductsToCardProducts',
        `Product with missing attributes provided by Algolia :
        objectId : ${product.objectID},
        name: ${product.name},
        price: ${product.price}
        `
      );
    }
    return productCardItems;
  }, []);
};
