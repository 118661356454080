import { PRICE_RANGES } from './priceRanges';

export const LOW_STOCK_LIMITS = {
  [PRICE_RANGES.HIGH]: 5,
  [PRICE_RANGES.MID]: 10,
  [PRICE_RANGES.LOW]: 15
} as const;

export const IN_STOCK = 'in-stock';
export const LOW_STOCK = 'low-stock';
export const OUT_OF_STOCK = 'out-of-stock';
export const BACK_IN_STOCK_SOON = 'back-in-stock-soon';
export const AVAILABLE_SOON = 'available-soon';

export type LowStockLimits = ValueOf<typeof LOW_STOCK_LIMITS>;
