


















import { defineComponent } from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';
import RenderContent from '~/cms/RenderContent.vue';
import { useInternalCms } from '~/composables';
import { COMPONENTS } from '~/constants/cms';
import { LinkType } from '~/types/components/Link/LinkType';
import Link from '~/components/atoms/Link/Link.vue';

export default defineComponent({
  name: 'CampaignAdvertBannerContainer',
  components: {
    RenderContent,
    Link
  },
  props: {
    bannerUrl: {
      type: String,
      required: true
    }
  },
  setup() {
    const {
      loadComponent: campaignAdvertBannerSearch,
      internalCmsContent: campaignAdvertBannerContent,
      canShowContent: canShowCampaignAdvertBanner
    } = useInternalCms(COMPONENTS.CAMPAIGN_ADVERT.SLUG);

    onSSR(async () => {
      await campaignAdvertBannerSearch();
    });

    return {
      campaignAdvertBannerContent,
      canShowCampaignAdvertBanner,
      LinkType
    };
  }
});
