import { BRANDS, BRANDS_WTH_SUBBRANDS } from '~/constants/brands';
import type { BrandInfo, BrandName } from '~/constants/brands';

export const getBrandInfo = (productBrand: string | undefined): BrandInfo | undefined => {
  if (!productBrand) {
    return;
  }

  const isBrandName = (name: string): name is BrandName => {
    return (name in BRANDS);
  };

  const getSubBrandName = (name: string): BrandName | undefined => {
    return BRANDS_WTH_SUBBRANDS.find(brandWithSubbrand => name.startsWith(brandWithSubbrand));
  };

  const getBrandName = (name: string): BrandName | undefined => {
    const nameLowerCase = name.toLowerCase();
    return isBrandName(nameLowerCase) ? nameLowerCase : getSubBrandName(nameLowerCase);
  };

  const brandName = getBrandName(productBrand);
  if (!brandName) {
    return;
  }

  const brandInfo = BRANDS[brandName];
  if (!brandInfo) {
    return;
  }

  return { name: productBrand, ...brandInfo };
};
