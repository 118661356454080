




import { defineComponent } from '@nuxtjs/composition-api';
import { createWidgetMixin } from 'vue-instantsearch';
import { connectPagination } from 'instantsearch.js/es/connectors';

export default defineComponent({
  name: 'VirtualPagination',
  mixins: [
    createWidgetMixin({ connector: connectPagination })
  ]
});
