















































import { defineComponent, ref } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { FaqAccordionItem } from '~/types/cms/AccordionItem';
import ProductAnchorTitle from '~/components/atoms/Product/ProductAnchorTitle/ProductAnchorTitle.vue';

export default defineComponent({
  name: 'FaqSection',
  components: {
    ProductAnchorTitle
  },
  props: {
    faq: {
      type: Array as PropType<FaqAccordionItem[]>,
      required: true
    }
  },
  setup() {
    const showMore = ref(false);
    const showMaxItems = 5;

    const hideExcessiveItems = (index: number) => index >= showMaxItems && !showMore.value;

    const toggleShowMore = () => {
      showMore.value = !showMore.value;
    };

    return {
      showMore,
      showMaxItems,
      toggleShowMore,
      hideExcessiveItems
    };
  }
});
