import type { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import { isSecondHandProduct } from '~/helpers/product/getProductInfo/getProductInfo';

export const getMetaDescription = (product: ProductVariantExtended): string | undefined => {
  return product?._meta?.description;
};

export const isPdpPageIndexed = (product: ProductVariantExtended): boolean => {
  return !isSecondHandProduct(product);
};
