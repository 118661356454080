




import { computed, defineComponent } from '@nuxtjs/composition-api';
import { createWidgetMixin } from 'vue-instantsearch';
import { connectRange } from 'instantsearch.js/es/connectors';

export default defineComponent({
  name: 'VirtualRangeInput',
  mixins: [
    createWidgetMixin({ connector: connectRange })
  ],
  props: {
    attribute: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const widgetParams = computed(() => {
      return { attribute: props.attribute };
    });

    return {
      widgetParams
    };
  }
});
