import { MAX_AMOUNT_OF_DESCRIPTION_SECTIONS } from '~/constants/numbers';
import type { ProductDescriptionMedia, ProductDescriptionSection } from '~/types/product/ProductDescription';
import type { ProductDescriptionRaw } from '~/types/product/attribute/ProductDescriptionRaw';
import { Product360Image } from '~/types/product/Product360Image';
import { ProductVideo } from '~/types/product/ProductVideo';

export const getProductDescriptionSections = ({
  productDescription,
  threeSixtyImage,
  videoInformation
}:{
  productDescription: ProductDescriptionRaw,
  threeSixtyImage: Maybe<Product360Image>,
  videoInformation: Maybe<ProductVideo>
}): ProductDescriptionSection[] => {
  const getDescriptionElement = (id: number | string, name: string): string => {
    return productDescription[`${name}_${id}`] || '';
  };

  const getHeader = (id: number | string): string => {
    return getDescriptionElement(id, 'h');
  };

  const getParagraph = (id: number): string => {
    return getDescriptionElement(id, 'p');
  };

  const mediaList: ProductDescriptionMedia[] = [];
  if (threeSixtyImage) {
    mediaList.push({ threeSixtyImage });
  }
  if (videoInformation) {
    mediaList.push({ videoUrl: videoInformation.link });
  }

  const sections: ProductDescriptionSection[] = [];

  const initialIndex = 1;
  let paragraphIndex = initialIndex;
  for (let headerIndex = initialIndex; headerIndex <= MAX_AMOUNT_OF_DESCRIPTION_SECTIONS; headerIndex++) {
    const isFirstSection = headerIndex === initialIndex;

    const title = getHeader(isFirstSection ? 'main' : headerIndex);
    const text1 = getParagraph(paragraphIndex);
    paragraphIndex += 1;

    let text2 = '';
    if (!isFirstSection) {
      text2 = getParagraph(paragraphIndex);
      paragraphIndex += 1;
    }

    const paragraphs = [text1, text2].filter(Boolean);
    if (!paragraphs.length) {
      continue;
    }

    if (!title && !isFirstSection) {
      sections[sections.length - 1]?.paragraphs.push(...paragraphs);
      continue;
    }

    sections.push({
      title,
      paragraphs,
      media: mediaList.shift()
    });
  }

  return sections;
};
