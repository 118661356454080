










import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useCategories } from '~/composables';

export default defineComponent({
  name: 'SideCategories',
  components: {
    SideCategory: () => import(/* webpackChunkName: "SideCategory" */
      '~/components/atoms/Search/SideCategory/SideCategory.vue')
  },
  setup() {
    const { categories } = useCategories();
    const topCategory = computed(() => {
      return categories.value?.[0];
    });

    return {
      topCategory
    };
  }
});
