













import { defineComponent } from '@nuxtjs/composition-api';
import { AisRangeInput } from 'vue-instantsearch';
import { ALGOLIA_ATTRIBUTE } from '~/constants/algolia';
import PricePickerInner from '~/components/molecules/Search/PricePickerInner.vue';

export default defineComponent({
  name: 'PricePicker',
  components: {
    AisRangeInput,
    PricePickerInner
  },
  setup() {
    return { ALGOLIA_ATTRIBUTE };
  }
});
