import type { SortingConfiguration } from 'vue-instantsearch';
import type { IVueI18n } from 'vue-i18n';
import { i18nToAlgoliaMainIndex } from '../locales/i18nCurrentDetails';
import { SORTING_METHODS } from '~/constants/algolia';
import { TYPESENSE_SORTING_TYPE_SPLIT } from '~/constants/regex';

/**
 * typesense expects the sorting value to be something like
 * "whatever-term-here/sort/price:asc" so that the adapter will extract only
 * "price:asc"
*/
export const generateTypesenseSortingIndex = (searchIndex: string, sortingType: string) => {
  let sortingNameForTypesense = '';
  if (sortingType === 'newest') {
    sortingNameForTypesense = 'releaseDate:desc';
  } else {
    sortingNameForTypesense = sortingType.split(TYPESENSE_SORTING_TYPE_SPLIT).join(':').toLowerCase();
  }

  return `${searchIndex}/sort/${sortingNameForTypesense}`;
};

export const generateAlgoliaSortingIndex = (searchIndex: string, sortingType: string) => {
  return [searchIndex, sortingType].join('-');
};

export const getAlgoliaSortingConfiguration = ($i18n: IVueI18n): SortingConfiguration[] => {
  const mainSearchIndex = i18nToAlgoliaMainIndex($i18n);

  const defaultSortingOption = SORTING_METHODS.map((sorting) => {
    if (!sorting.value) {
      sorting.value = mainSearchIndex;
    }

    if (!sorting.value.includes(mainSearchIndex)) {
      sorting.value = generateTypesenseSortingIndex(mainSearchIndex, sorting.value);
    }

    if ($i18n) {
      sorting.label = $i18n.t(sorting.label).toString();
    }

    return sorting;
  });

  return defaultSortingOption;
};
