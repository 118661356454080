





















import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { debounce } from 'lodash';
import { DEBOUNCE_TIMES } from '~/constants/debounceTimes';
import type { ValueRange, ValueRangeOptional } from '~/types/ValueRange';
import CurrencyInput from '~/components/atoms/Inputs/CurrencyInput/CurrencyInput.vue';

export default defineComponent({
  name: 'RangeInput',
  components: {
    CurrencyInput
  },
  props: {
    currentRange: {
      type: Object as PropType<ValueRangeOptional>,
      required: true
    },
    maxRange: {
      type: Object as PropType<ValueRange>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const minValue = computed(() =>
      props.currentRange.min === props.maxRange.min
        ? null
        : props.currentRange.min
    );
    const maxValue = computed(() =>
      props.currentRange.max === props.maxRange.max
        ? null
        : props.currentRange.max
    );

    const indexKey = ref(0);

    function sendInput(range: ValueRange): void {
      indexKey.value += 1; // needed to refresh the inputs, when setting the same value for the second time
      emit('input', range);
    }

    const debounceInput = debounce(sendInput, DEBOUNCE_TIMES.NUMBER_INPUT);

    function setMin(value: Nullable<number>) {
      let min = value ?? props.maxRange.min;
      min = Math.min(min, props.currentRange.max ?? -Infinity);
      min = Math.max(min, props.maxRange.min);

      const max = props.currentRange.max ?? props.maxRange.min;
      debounceInput({ min, max });
    }

    function setMax(value: Nullable<number>) {
      let max = value ?? props.maxRange.max;
      max = Math.max(max, props.currentRange.min ?? Infinity);
      max = Math.min(max, props.maxRange.max);

      const min = props.currentRange.min ?? props.maxRange.min;
      debounceInput({ min, max });
    }

    return {
      setMin,
      setMax,
      minValue,
      maxValue,
      indexKey
    };
  }
});
