














import { ref, computed, defineComponent, watch } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { useI18n } from '~/composables';

export default defineComponent({
  name: 'CurrencyInput',
  props: {
    value: {
      type: Number as PropType<Nullable<number>>,
      default: null
    },
    placeholder: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { currencyCode } = useI18n();
    const { i18n } = useVSFContext();

    const inputValue = ref<Nullable<number>>(props.value);
    const focused = ref(false);

    watch(() => props.value, (newValue) => {
      inputValue.value = newValue;
    });

    const emitInput = (value: Nullable<number>) => {
      emit('input', value);
    };

    function formatNumberAsCurrency(value: Nullable<number>): string {
      return value
        ? i18n.n(value, {
          style: 'currency',
          currency: currencyCode.value,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        })
        : '';
    }

    const formattedPlaceholder = computed(() => formatNumberAsCurrency(props.placeholder));
    const formattedValue = computed(() => focused.value ? inputValue.value : formatNumberAsCurrency(inputValue.value));
    const inputType = computed(() => focused.value ? 'number' : 'text');

    function onInput(value: string): void {
      const numericValue = parseInt(value) || null;
      inputValue.value = numericValue;
      emitInput(numericValue);
    }

    function onFocus() {
      focused.value = true;
    }

    function onBlur() {
      focused.value = false;
    }

    return {
      inputValue,
      inputType,
      emitInput,
      onInput,
      onFocus,
      onBlur,
      formattedValue,
      formattedPlaceholder
    };
  }
});
