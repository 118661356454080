













import {
  AisInstantSearchSsr
} from 'vue-instantsearch';
import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent } from '@nuxtjs/composition-api';
import { createServerRootMixin } from 'vue-instantsearch/src/instantsearch';
import type { ProductListingFilter } from '~/types/cms/ProductListing/ProductListingFilter';
import type { ProductGroupsItemType } from '~/types/cms/ProductGroupsItem';
import { getAlgoliaClientWithAllFacets } from '@/helpers/algolia/algolia';
import { $cloneComponent, algoliaRouter, stateMapping } from '~/helpers/algolia/algoliaSSR/algoliaSSR';
import { i18nToAlgoliaMainIndex } from '~/helpers/locales/i18nCurrentDetails';
import SearchResults from '~/components/organisms/Search/SearchResults.vue';
import { algoliaSSRRequiredFields } from '~/mixins/algoliaSSRRequiredFields';
import localizationDefinitions from '~/i18n/localizationDefinitions';
import { getAlgoliaIndexName } from '~/i18n/algoliaLocalization';

const isSSRMode = true;
// Component based on Algolia doc:
// https://www.algolia.com/doc/guides/building-search-ui/going-further/server-side-rendering/vue/#with-nuxt
export default defineComponent({
  name: 'AlgoliaContainer',
  components: {
    SearchResults,
    AisInstantSearchSsr
  },
  mixins: [algoliaSSRRequiredFields()],
  props: {
    categoryFilter: {
      type: String,
      default: ''
    },
    languageAndCountry: {
      type: String,
      default: null
    },
    enableStateMapping: {
      type: Boolean,
      default: false
    },
    preFilter: {
      type: Object as PropType<ProductListingFilter>,
      default: () => ({})
    },
    description: {
      type: String,
      default: ''
    },
    productGroupItems: {
      type: Array as PropType<Array<ProductGroupsItemType>>,
      default: () => []
    },
    productGroupItemsTextColor: {
      type: String,
      default: 'text-EXPONDO-black'
    },
    productGroupItemsBackgroundColor: {
      type: String,
      default: 'bg-EXPONDO-grey-5'
    }
  },
  data() {
    const algoliaIndexName = getAlgoliaIndexName(this.$props.languageAndCountry, localizationDefinitions);
    const routing: {
      router: ReturnType<typeof algoliaRouter>;
      stateMapping?: ReturnType<typeof stateMapping>;
    } = {
      router: algoliaRouter(this.$nuxt?.$router ?? this.$router, isSSRMode, algoliaIndexName)
    };

    if (this.enableStateMapping) {
      routing.stateMapping = stateMapping(algoliaIndexName, this.preFilter);
    }
    const mixin = createServerRootMixin({
      searchClient: getAlgoliaClientWithAllFacets(this.$props.languageAndCountry),
      indexName: i18nToAlgoliaMainIndex(this.$i18n),
      $cloneComponent,
      routing
    });
    return {
      ...mixin.data()
    };
  },
  watch: {
    'instantsearch.helper.state.page'(updatedPage) {
      const newPage = updatedPage + 1;
      this.$emit('page-changed', newPage);
    }
  }
});

