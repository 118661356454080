








import { defineComponent } from '@nuxtjs/composition-api';
import { AisClearRefinements } from 'vue-instantsearch';

export default defineComponent({
  name: 'ClearRefinements',
  components: {
    AisClearRefinements
  }
});
