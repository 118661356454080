













import { defineComponent } from '@nuxtjs/composition-api';
import { useParcelPerform } from '~/composables';

export default defineComponent({
  name: 'Tracking',
  layout: 'default',
  setup() {
    const { isLoading } = useParcelPerform();
    return {
      isLoading
    };
  }
});
