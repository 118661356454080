
















import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { RefinementListItem } from 'vue-instantsearch';
import CheckBox from '~/components/atoms/Buttons/CheckBox/CheckBox.vue';
import FacetCount from '~/components/atoms/Search/FacetCount/FacetCount.vue';
import { useAlgolia } from '~/composables';

export default defineComponent({
  name: 'CheckboxPickerOption',
  components: {
    CheckBox,
    FacetCount
  },
  props: {
    checkboxItem: {
      type: Object as PropType<RefinementListItem>,
      required: true
    },
    refine: {
      type: Function,
      required: true
    },
    translateAttributes: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { getAlgoliaFilterName } = useAlgolia();

    const refinementListItemLabel = computed(() =>
      getAlgoliaFilterName(props.checkboxItem.value)
    );
    return {
      refinementListItemLabel
    };
  }
});
