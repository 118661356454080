



























import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { SCHEMA_ITEM_TYPE, SCHEMA_ITEM_PROP } from '~/constants/seo';

export default defineComponent({
  name: 'ReviewAuthor',
  props: {
    author: {
      type: String,
      default: ''
    },
    timeAgo: {
      type: String,
      required: true
    },
    source: {
      type: String,
      default: ''
    },
    isTopReview: {
      type: Boolean,
      default: false
    },
    showSchema: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const { i18n } = useVSFContext();
    const reviewAuthor = computed(() => props.author || i18n.t('Anonymous'));
    return {
      reviewAuthor,
      SCHEMA_ITEM_TYPE,
      SCHEMA_ITEM_PROP
    };
  }
});
