









































import { computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { RefinementListItem } from 'vue-instantsearch';
import CheckBox from '~/components/atoms/Buttons/CheckBox/CheckBox.vue';
import FacetCount from '~/components/atoms/Search/FacetCount/FacetCount.vue';
import ReviewsStars from '~/components/atoms/ReviewsStars.vue';
import { RATING_TO_IGNORE } from '~/constants/algolia';
import { MAX_POINTS_FOR_RATINGS, MIN_POINTS_FOR_RATINGS } from '~/constants/reviews';

export default defineComponent({
  name: 'RatingMenuInner',
  components: {
    CheckBox,
    FacetCount,
    ReviewsStars
  },
  props: {
    items: {
      type: Array as PropType<RefinementListItem[]>,
      required: true
    },
    refine: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const filteredItems = computed(() => props.items.filter(
      item => item.value !== RATING_TO_IGNORE && item.count !== 0
    ));

    const minRating = MIN_POINTS_FOR_RATINGS.toString();
    const maxRating = MAX_POINTS_FOR_RATINGS.toString();

    return { filteredItems, minRating, maxRating };
  }
});
