





































import type { PropType } from '@nuxtjs/composition-api';
import VueScrollTo from 'vue-scrollto';
import { defineComponent, computed, toRefs } from '@nuxtjs/composition-api';
import { reviewGetters } from '@vsf-enterprise/commercetools';
import type { ProductReview } from '~/types/product/attribute/ProductReview';
import { useI18n } from '~/composables/useI18n';
import { getTimeAgo } from '~/helpers/time/getTimeAgo';
import ReviewsStars from '~/components/atoms/ReviewsStars.vue';
import ReviewAuthor from '~/components/atoms/Review/ReviewAuthor/ReviewAuthor.vue';
import { useAnchorNavigation } from '~/composables';
import { PDP_NAV_ANCHORS } from '~/constants/pdpNavAnchors';
import { getTranslatedCustomField } from '~/helpers/review/getTranslatedCustomField/getTranslatedCustomField';

export default defineComponent({
  name: 'ProductTopReview',
  components: {
    ReviewsStars,
    ReviewAuthor
  },
  props: {
    review: {
      type: Object as PropType<ProductReview>,
      default: () => {
        return {};
      }
    }
  },
  setup(props) {
    const { review } = toRefs(props);
    const { languageAndCountry: activeLocale } = useI18n();
    const relativeTime = computed(() =>
      new Intl.RelativeTimeFormat(activeLocale.value, { style: 'long', numeric: 'auto' }));

    const date = computed(() => review.value.custom?.reviewCreatedAt?.[0]?.value || review.value.createdAt);
    const time = computed(() => getTimeAgo(date.value ? new Date(date.value) : undefined).time);
    const unit = computed(() => getTimeAgo(date.value ? new Date(date.value) : undefined).unit);
    const timeAgo = computed(() => time.value && unit.value ? relativeTime.value?.format(time.value, unit.value) : '');
    const translatedTitle = computed(() =>
      getTranslatedCustomField({ review: review.value, field: 'titleTranslations', activeLocale: activeLocale.value }));
    const translatedMessage = computed(() =>
      getTranslatedCustomField({ review: review.value, field: 'textTranslations', activeLocale: activeLocale.value }));
    const originalMessage = computed(() => reviewGetters.getReviewMessage(review.value));
    const title = computed(() => translatedTitle.value || review.value?.title);
    const message = computed(() => translatedMessage.value || originalMessage.value);
    const isReviewEmpty = computed(() => !originalMessage.value);

    const rating = computed(() => reviewGetters.getReviewRating(review.value));
    const author = computed(() => reviewGetters.getReviewAuthor(review.value));
    const shouldShowReview = computed(() => review && rating.value >= 4);

    const { stickyAnchorNavHeight } = useAnchorNavigation();
    const scrollToReviews = () => {
      VueScrollTo.scrollTo(`#${PDP_NAV_ANCHORS.RATING_REVIEWS}`, {
        offset: -stickyAnchorNavHeight.value
      });
    };

    return {
      rating,
      author,
      title,
      message,
      timeAgo,
      isReviewEmpty,
      scrollToReviews,
      shouldShowReview
    };
  }
});
