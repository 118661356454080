























import { defineComponent, toRefs, computed, onMounted, ref } from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import RenderContent from '~/cms/RenderContent.vue';
import { useAbsoluteUrlPage, useCategories, useMetaExtended, usePageViewEvent } from '~/composables';
import type { DynamicPageContent } from '~/types/cms/DynamicPageContent';
import TrustedBy from '~/components/molecules/Product/TrustedBy.vue';
/*
* This page is prepared for CMS dynamic content rendering.
*/
export default defineComponent({
  name: 'DynamicCmsPage',
  components: {
    RenderContent,
    TrustedBy
  },
  props: {
    content: {
      type: Array,
      required: true
    },
    currentPageSlug: {
      type: String,
      required: true
    },
    isHomePage: {
      type: Boolean,
      required: true
    }
  },
  setup (props: {
    content: DynamicPageContent
    currentPageSlug: string,
    isHomePage: boolean
  }) {
    const { content, currentPageSlug, isHomePage } = toRefs(props);
    const { callPageViewEvent } = usePageViewEvent();
    const {
      isValidCategoryCMSPage,
      cmsSideMenu,
      cmsPageContent,
      isInsideContainer,
      seoContent,
      isComponentsOverviewPage
    } = useAbsoluteUrlPage(content);
    const { addTags } = useCache();
    const { searchWithDefaultParameters, getters: { currentCategorySlugAllLocales } } = useCategories();
    onSSR(async () => {
      if (isValidCategoryCMSPage.value) {
        await searchWithDefaultParameters(currentPageSlug.value);
      }
      addTags([
        { prefix: CacheTagPrefix.View, value: 'cmspage' },
        { prefix: CacheTagPrefix.Block, value: currentPageSlug.value }
      ]);
    });

    // TODO: add the definition of title and adjust meta tags to be visible:
    // https:// expondo.atlassian.net/browse/INSP-1154
    useMetaExtended({
      metaTitle: computed(() => seoContent.value?.title),
      isPageIndexed: ref(true),
      slug: currentPageSlug,
      localisedSlugs: computed(() => isValidCategoryCMSPage.value ? currentCategorySlugAllLocales.value : []),
      isPageHreflangLinkHidden: computed(() => !isHomePage.value)
    });
    onMounted(() => {
      callPageViewEvent();
    });

    return {
      cmsPageContent,
      cmsSideMenu,
      isInsideContainer,
      isValidCategoryCMSPage,
      isComponentsOverviewPage
    };
  },
  head: {}
});
