













































import { computed, defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { HitResultItem } from 'vue-instantsearch';
import { onSSR, useVSFContext } from '@vue-storefront/core';
import ProductCard from '@/components/molecules/Product/ProductCard.vue';
import { searchView } from '~/types/search/SearchView';
import { convertAlgoliaProductsToCardProducts } from '~/helpers/algolia/convertAlgoliaProductsToCardProducts';
import {
  useIsPage,
  useAlgoliaGtmEvents,
  useRouteBestPriceGuarantee,
  useProductDeliveryDate,
  useViewItemListEvent,
  useProductForListing,
  useProductBoxClickLocation
} from '~/composables';
import { ProductCardItem } from '~/types/product/ProductCardItem';
import ProductDimensionsInterface from '~/types/product/attribute/ProductDimensions';
import { getEnergyRating } from '~/helpers/product/energyRating';

export default defineComponent({
  name: 'ProductListing',
  components: {
    ProductCard
  },
  props: {
    items: {
      type: Array as PropType<HitResultItem[]>,
      default: () => {
        return [];
      }
    },
    listType: {
      type: String,
      default: searchView.Grid
    },
    query: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const { route: currentRoute } = useVSFContext();
    const { isOnSearchPage } = useIsPage();
    const { callViewHitsEvent, callClickHitEvent } = useAlgoliaGtmEvents();
    const { priceGuaranteeUrl } = useRouteBestPriceGuarantee();
    const { search: searchProductDeliveryDate, parcelDeliveryDate, freightDeliveryDate } = useProductDeliveryDate(true);
    const currentSlug = currentRoute.value.params.slug || '';
    const { callViewItemListEvent } = useViewItemListEvent(props.items, { searchTerm: props.query, currentSlug });

    onMounted(() => {
      emit('product-listing-shown', props.items);
      callViewItemListEvent();
      if (isOnSearchPage.value) {
        callViewHitsEvent(props.items);
      }
    });
    const productListingDisplay = computed(() => {
      if (props.listType === searchView.Grid) {
        return 'grid lg:grid';
      } else {
        return 'grid lg:flex list';
      }
    });

    const onProductClicked = (product: HitResultItem) => {
      if (isOnSearchPage.value) {
        callClickHitEvent(product, props.query);
      }
    };

    const getDimensions = (item: ProductCardItem): ProductDimensionsInterface => {
      return {
        height: item.height,
        width: item.width,
        length: item.length,
        hasDimensions: item.hasDimensions
      };
    };

    const transformedProducts = computed(() => convertAlgoliaProductsToCardProducts(props.items));

    const {
      search: searchProduct,
      masterProductLocalisedAttributes: attributesLocalized,
      masterProduct: product
    } = useProductForListing();

    const timeout = ref(null);

    const getProductAttributes = (link: string, sku: string) => {
      if (product.value && product.value.sku === sku) {
        return;
      }
      const searchParams = {
        slug: link.slice(1)
      };

      clearTimeout(timeout.value);
      timeout.value = setTimeout(async () => {
        await searchProduct(searchParams);
      }, 300);
    };

    const cancelDataFetching = () => {
      clearTimeout(timeout.value);
    };

    const { productBoxClickLocation } = useProductBoxClickLocation();

    onSSR(async () => {
      await searchProductDeliveryDate();
    });

    return {
      productListingDisplay,
      transformedProducts,
      onProductClicked,
      priceGuaranteeUrl,
      getDimensions,
      parcelDeliveryDate,
      freightDeliveryDate,
      getProductAttributes,
      cancelDataFetching,
      attributesLocalized,
      productBoxClickLocation,
      getEnergyRating
    };
  }
});
