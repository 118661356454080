


















import { computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { CurrentRefinementItem } from 'vue-instantsearch';
import { ALGOLIA_ATTRIBUTE, MAX_STARS } from '~/constants/algolia';
import { useAlgolia } from '~/composables';

export default defineComponent({
  name: 'CurrentRefinementLabel',
  props: {
    refinement: {
      type: Object as PropType<CurrentRefinementItem>,
      required: true
    },
    createUrl: {
      type: Function as PropType<(refinement: CurrentRefinementItem) => string>,
      required: true
    }
  },
  setup (props) {
    const { getAlgoliaFilterName } = useAlgolia();

    const currentRefinementLabel = computed(() =>
      getAlgoliaFilterName(props.refinement.label, props.refinement.attribute)
    );

    const refinementUrl = computed(() => props.createUrl(props.refinement));

    const shouldHideLabel = computed(() =>
      props.refinement.attribute === ALGOLIA_ATTRIBUTE.AVG_RATING &&
      props.refinement.value === MAX_STARS
    );

    return {
      currentRefinementLabel,
      refinementUrl,
      shouldHideLabel
    };
  }
});
