






















































































































import { defineComponent } from '@nuxtjs/composition-api';
import { useI18n, useTrustPilotWidget } from '~/composables';
import {
  TRUSTPILOT_MINI_WIDGET_WIDTH,
  TRUSTPILOT_MINI_WIDGET_HEIGHT
} from '~/constants/ui';
import { trustpilotMiniWidgetId } from '~/config';

export default defineComponent({
  name: 'TrustedBy',
  props: {
    displayTrustpilot: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { languageAndCountry } = useI18n();
    const { showTrustPilotWidget } = useTrustPilotWidget();

    return {
      languageAndCountry,
      showTrustPilotWidget,
      trustpilotMiniWidgetId,
      TRUSTPILOT_MINI_WIDGET_WIDTH,
      TRUSTPILOT_MINI_WIDGET_HEIGHT
    };
  }
});
