import {
  MILISECONDS_IN_DAY, MILISECONDS_IN_HOUR,
  MILISECONDS_IN_MINUTE, MILLISECONDS_IN_SECOND
} from '~/constants/timeUnits';
import { TimeRemaining } from '~/types/generic/time';
import parseCustomDateString from '~/helpers/time/parseCustomDateString';

export default function calculateRemainingTime(inputDateString: string): TimeRemaining | null {
  const inputDate = parseCustomDateString(inputDateString);
  const currentUTC = new Date();
  const timeDifference = inputDate.getTime() - currentUTC.getTime();
  const isInputDateInPast = timeDifference <= 0;

  if (isInputDateInPast) {
    return null;
  }

  const remainingTimeInMilliseconds = timeDifference % MILISECONDS_IN_DAY;

  const days = Math.floor(timeDifference / MILISECONDS_IN_DAY);
  const hours = Math.floor(remainingTimeInMilliseconds / MILISECONDS_IN_HOUR);
  const minutes = Math.floor((remainingTimeInMilliseconds % MILISECONDS_IN_HOUR) / MILISECONDS_IN_MINUTE);
  const seconds = Math.floor((remainingTimeInMilliseconds % MILISECONDS_IN_MINUTE) / MILLISECONDS_IN_SECOND);

  return {
    days,
    hours,
    minutes,
    seconds
  };
}
