




















import { defineComponent, computed, onMounted, ref } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { PDP_NAV_ANCHORS } from '~/constants/pdpNavAnchors';
import ProductAnchorNavItem from '~/components/atoms/Product/ProductAnchorNavItem.vue';
import { useAnchorNavigation, useProductExtended } from '~/composables';

export default defineComponent({
  name: 'ProductAnchorNav',
  components: {
    ProductAnchorNavItem
  },
  props: {
    shouldShowDescription: {
      type: Boolean,
      required: true
    },
    shouldShowFaq: {
      type: Boolean,
      required: true
    },
    shouldShowWarranty: {
      type: Boolean,
      required: true
    },
    shouldShowRelatedAccessories: {
      type: Boolean,
      required: true
    },
    shouldShowParentsForAccessory: {
      type: Boolean,
      required: true
    },
    shouldShowAboutBrand: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { root: { $route } }) {
    const { i18n } = useVSFContext();
    const { setStickyAnchorNav } = useAnchorNavigation();
    const stickyAnchorNavRef = ref(null);
    const { slug } = $route.params;
    const { loaded: productDataLoaded } = useProductExtended(slug);

    const anchorNavItems = computed(() => [
      {
        condition: productDataLoaded.value,
        sectionId: PDP_NAV_ANCHORS.TECHNICAL_DETAILS,
        text: i18n.t('Technical details')
      },
      {
        condition: props.shouldShowDescription,
        sectionId: PDP_NAV_ANCHORS.PRODUCT_DESCRIPTION,
        text: i18n.t('Product Description')
      },
      {
        condition: props.shouldShowRelatedAccessories,
        sectionId: PDP_NAV_ANCHORS.RELATED_ACCESSORIES,
        text: i18n.t('Accessories')
      },
      {
        condition: props.shouldShowParentsForAccessory,
        sectionId: PDP_NAV_ANCHORS.PARENTS_FOR_ACCESSORY,
        text: i18n.t('Matches to')
      },
      {
        condition: props.shouldShowWarranty,
        sectionId: PDP_NAV_ANCHORS.WARRANTY,
        text: i18n.t('Warranty')
      },
      {
        condition: props.shouldShowAboutBrand,
        sectionId: PDP_NAV_ANCHORS.ABOUT_BRAND,
        text: i18n.t('About the brand')
      },
      {
        condition: true,
        sectionId: PDP_NAV_ANCHORS.RATING_REVIEWS,
        text: i18n.t('Ratings & reviews')
      },
      {
        condition: props.shouldShowFaq,
        sectionId: PDP_NAV_ANCHORS.FAQ,
        text: i18n.t('Frequently asked questions')
      }
    ]);

    onMounted(() => {
      if (stickyAnchorNavRef.value) {
        setStickyAnchorNav(stickyAnchorNavRef.value);
      }
    });

    return {
      PDP_NAV_ANCHORS,
      anchorNavItems,
      stickyAnchorNavRef // Make sure to return the ref
    };
  }
});
