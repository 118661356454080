


























import { defineComponent, computed } from '@vue/composition-api';
import { productGetters } from '@vsf-enterprise/commercetools';
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import type { PropType } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import ProductTitle from '~/components/atoms/Product/ProductTitle.vue';
import type { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';

export default defineComponent({
  name: 'ProductDetailsSection',
  components: {
    LazyHydrate,
    ProductAverageReview: () => import(/* webpackChunkName: "ProductAverageReview" */
      '~/components/atoms/Product/ProductAverageReview.vue'),
    ProductTitle,
    ProductAttributeWrapper: () => import(/* webpackChunkName: "ProductAttributeWrapper" */
      '~/components/organisms/Product/ProductAttributeWrapper.vue')
  },
  props: {
    product: {
      type: Object as PropType<ProductVariant>,
      required: true
    },
    attributesLocalized: {
      type: Object as PropType<ProductProcessedAttributes>,
      required: true
    }
  },
  setup(props) {
    const productTitle = computed(() => productGetters.getName(props.product));

    return {
      productTitle
    };
  }
});
