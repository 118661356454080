









import { computed, defineComponent } from '@vue/composition-api';
import { getBrandInfo } from '~/helpers/brand';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';

export default defineComponent({
  name: 'ProductCardBrand',
  components: {
    ImgView
  },
  props: {
    brand: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const brandLogo = computed(() => getBrandInfo(props.brand)?.logo);

    return { brandLogo };
  }
});
