export const TIME_UNITS = {
  YEAR: 'year',
  MONTH: 'month',
  DAY: 'day'
} as const;

export const PROMO_BANNER_INTERVAL_MILISECONDS = 5000;

export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const MILISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE;
export const MILISECONDS_IN_HOUR = MILISECONDS_IN_MINUTE * MINUTES_IN_HOUR;
export const MILISECONDS_IN_DAY = MILISECONDS_IN_HOUR * HOURS_IN_DAY;
