










import {
  computed,
  defineComponent
} from '@nuxtjs/composition-api';
import { useI18n } from '~/composables';
import { isLowStock } from '~/helpers/product/getLowStock';
import { IN_STOCK, LOW_STOCK, BACK_IN_STOCK_SOON, AVAILABLE_SOON } from '~/constants/lowStockLimits';

export default defineComponent({
  name: 'StockInformation',
  props: {
    price: {
      type: Number,
      default: null
    },
    stockCount: {
      type: Number,
      required: true
    },
    isInStock: {
      type: Boolean,
      required: true
    },
    isTestProduct: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const { countryCode } = useI18n();

    const isLowStockInfo = computed(() =>
      isLowStock(countryCode.value, props.price, props.stockCount)
        ? LOW_STOCK
        : IN_STOCK);

    const stockInformation = computed(() => {
      if (props.isTestProduct) {
        return AVAILABLE_SOON;
      } else if (!props.isInStock) {
        return BACK_IN_STOCK_SOON;
      } else {
        return isLowStockInfo.value;
      }
    });

    const color = computed(() => {
      if (
        stockInformation.value === AVAILABLE_SOON ||
        stockInformation.value === BACK_IN_STOCK_SOON
      ) {
        return 'text-EXPONDO-grey-60';
      } else if (stockInformation.value === LOW_STOCK) {
        return 'text-EXPONDO-red';
      } else {
        return 'text-EXPONDO-green';
      }
    });

    return {
      stockInformation,
      color
    };
  }
});
