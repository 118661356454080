






import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useIntersectionObserver } from '@vueuse/core';
import { useAnchorNavigation } from '~/composables';
import type { AnchorNavOptions } from '~/types/product/AnchorNav';

export default defineComponent({
  name: 'AnchorNavigationSectionWrapper',
  setup (_props) {
    const observerSection = ref(null);
    const { setActiveSection, observerRootMargin } = useAnchorNavigation();
    useIntersectionObserver(observerSection, ([entry]) => {
      if (entry.isIntersecting) {
        const id = entry.target?.getAttribute('id') as AnchorNavOptions;
        setActiveSection(id);
      }
    },
    {
      threshold: 0,
      rootMargin: observerRootMargin.value
    });

    return {
      observerSection
    };
  }
});
