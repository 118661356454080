




















































import { defineComponent } from '@vue/composition-api';
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import type { PropType } from '@nuxtjs/composition-api';
import { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';
import ProductHeader from '~/components/organisms/Product/ProductHeader/ProductHeader.vue';
import ProductPricingSection from '~/components/organisms/Product/ProductPricingSection/ProductPricingSection.vue';
import ProductQuickDetails from '~/components/organisms/Product/ProductQuickDetails/ProductQuickDetails.vue';

import ProductTopReview from '~/components/molecules/Product/ProductTopReview/ProductTopReview.vue';
import type { ProductReview } from '~/types/product/attribute/ProductReview';
import { useFloatingATCSection } from '~/composables';

export default defineComponent({
  name: 'ProductDetailsSection',
  components: {
    FloatingAddToCart: () => import(/* webpackChunkName: "FloatingAddToCart" */
      '~/components/organisms/Product/FloatingAddToCart/FloatingAddToCart.vue'),
    ProductDetailsMetaSchema: () => import(/* webpackChunkName: "ProductDetailsMetaSchema" */
      '~/components/molecules/Product/details/ProductDetailsMetaSchema.vue'),
    ProductGallery: () => import(/* webpackChunkName: "ProductGallery" */
      '~/components/molecules/Product/ProductGallery.vue'),
    ProductHeader,
    ProductPricingSection,
    ProductQuickDetails,
    ProductTopReview
  },
  props: {
    product: {
      type: Object as PropType<ProductVariant>,
      required: true
    },
    attributesLocalized: {
      type: Object as PropType<ProductProcessedAttributes>,
      required: true
    },
    productAdjustedForCart: {
      type: Object,
      required: true
    },
    availableQuantity: {
      type: Number,
      required: true
    },
    stock: {
      type: Number,
      required: true
    },
    topReview: {
      type: Object as PropType<ProductReview>,
      default: () => {
        return {};
      }
    },
    shouldShowRelatedAccessories: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const { showFloatingAddToCart } = useFloatingATCSection();

    return {
      showFloatingAddToCart
    };
  }
});
