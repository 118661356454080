






















import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductCardOverlay',
  props: {
    keyFacts: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    link: {
      type: String,
      required: true
    }
  }
});
