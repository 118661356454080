import { HierarchicalMenuItem } from 'vue-instantsearch';
import { CATEGORY_PATHS } from '~/constants/algolia';
/**
 * to be used by ais-hierarchical-menu component sort-by property according to native js sort function logic
 */
export const getCustomCategoryTreeOrder = (firstMenuItem: HierarchicalMenuItem, secondMenuItem: HierarchicalMenuItem) => {
  // 'Factory Seconds' should come last among the main categories
  if (firstMenuItem.path === CATEGORY_PATHS.FACTORY_SECONDS) {
    return 1;
  }
  if (secondMenuItem.path === CATEGORY_PATHS.FACTORY_SECONDS) {
    return -1;
  }

  // Remaining categories should be listed in alphabetical order according to localised name
  if (firstMenuItem.name > secondMenuItem.name) {
    return 1;
  }
  if (firstMenuItem.name < secondMenuItem.name) {
    return -1;
  }

  return 0;
};