import type { Category } from '@vsf-enterprise/commercetools-types';
import { CategoryTree } from '~/constants/categoryTree';
import { buildCategoryPageUrl } from '~/helpers/category/buildCategoryPageUrl';
import { findCategoryTreeByKey, removeEmptyCategories } from '~/helpers/category/categoriesTree';
import type { RelatedCategory, RelatedTree } from '~/types/category/RelatedCategory';

const parseRelatedCategories = (categories: Category[], categoryTree?: CategoryTree): RelatedCategory[] => {
  return categories?.map((category) => ({
    name: category.name || '',
    url: buildCategoryPageUrl(category.slug || '', categoryTree)
  })) ?? [];
};

export const getRelatedTree = (rawCategories: Category[], languageAndCountry: string): Maybe<RelatedTree> => {
  const filteredRawCategories = removeEmptyCategories(rawCategories, languageAndCountry);
  if (!filteredRawCategories?.length) {
    return null;
  }

  const rootCategory = filteredRawCategories?.[0]?.ancestors[0];
  return {
    title: rootCategory?.name || '',
    categories: parseRelatedCategories(filteredRawCategories, findCategoryTreeByKey(rootCategory?.key))
  };
};
