
































import { defineComponent, onMounted, onBeforeUnmount, reactive, computed } from '@nuxtjs/composition-api';
import calculateRemainingTime from '~/helpers/time/calculateRemainingTime';
import isDateTodayOrPast from '~/helpers/time/isDateTodayOrPast';
import { CampaignCountdownState } from '~/components/molecules/Product/CampaignCountdown/types/CampaignCountdownState';
import CampaignCountdownSection from '~/components/molecules/Product/CampaignCountdown/CampaignCountdownSection.vue';

export default defineComponent({
  name: 'CampaignCountdown',
  components: {
    CampaignCountdownSection
  },
  props: {
    countdownStartDate: {
      type: String,
      default: ''
    },
    campaignEndDate: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      default: '#1A1A1A'
    },
    textColor: {
      type: String,
      default: '#fff'
    }
  },
  setup(props) {
    const canShowCountdown = computed(() => {
      return props.countdownStartDate ? isDateTodayOrPast(props.countdownStartDate) : true;
    });
    const state: CampaignCountdownState = reactive({
      remainingTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    });
    const setRemainingTime = () => {
      state.remainingTime = calculateRemainingTime(props.campaignEndDate);
    };
    onMounted(() => {
      setRemainingTime();
    });
    const updateInterval = setInterval(() => {
      setRemainingTime();
    }, 1000);
    onBeforeUnmount(() => {
      clearInterval(updateInterval);
    });
    return {
      canShowCountdown,
      state
    };
  }
});
