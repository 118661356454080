import {
  FULL_DAY_IN_SECONDS,
  FULL_MONTH_IN_SECONDS,
  FULL_YEAR_IN_SECONDS
} from '~/constants/numbers';
import { TIME_UNITS } from '~/constants/timeUnits';

type TimeAgo = {
  unit?: ValueOf<typeof TIME_UNITS>,
  time?: number
}

type TimeDivisorUnits = {
  unit: ValueOf<typeof TIME_UNITS>,
  divisor: number
}
export const getTimeAgo = (date?: Date): TimeAgo => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return {
      time: undefined,
      unit: undefined
    };
  }
  const currentDate = new Date();
  const seconds = Math.floor((+currentDate - +date) / 1000);
  let timeAgo: TimeAgo = {
    time: 0,
    unit: TIME_UNITS.DAY
  };
  const units: TimeDivisorUnits[] = [
    { unit: TIME_UNITS.YEAR, divisor: FULL_YEAR_IN_SECONDS },
    { unit: TIME_UNITS.MONTH, divisor: FULL_MONTH_IN_SECONDS },
    { unit: TIME_UNITS.DAY, divisor: FULL_DAY_IN_SECONDS }
  ];

  for (const unit of units) {
    const interval = Math.floor(seconds / unit.divisor);
    if (interval >= 1) {
      timeAgo = {
        time: -interval,
        unit: unit.unit
      };
      break;
    }
  }
  return timeAgo;
};
