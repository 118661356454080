import VueScrollTo from 'vue-scrollto';

export const scrollToSection = (selector: string, offset: number = 0) => {
  if (!selector) {
    return;
  }
  VueScrollTo.scrollTo(selector, {
    offset
  });
};
