









import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { RefinementListItem } from 'vue-instantsearch';

export default defineComponent({
  name: 'FacetCount',
  props: {
    item: {
      type: Object as PropType<RefinementListItem>,
      required: true
    }
  }
});
