import { isProductPageSlug, isValidCmsPage } from '../../../helpers/routes/getPageInformation';
import { WHITELIST_URL_PARAMETERS_FOR_INDEXING } from '../../../constants/seo';
import { QUERY_PARAMETER_EQUALS } from '../../../constants/routes';

const isWhitelistedParam = (param: string): boolean => {
  return WHITELIST_URL_PARAMETERS_FOR_INDEXING.includes(param);
};

export const hasOnlyWhitelistedParams = (queryParams: Record<string, string | string[]>): boolean => {
  return Object.keys(queryParams).every(key => isWhitelistedParam(key));
};

export const checkCategoryPageStatus = ({
  slug, params, languageAndCountry
}: {
  slug: string,
  params: string[],
  languageAndCountry: string
}): {
  isIndexedCategoryPage: boolean,
  isUnindexedCategoryPage: boolean
} => {
  const isCategoryPage = !isProductPageSlug(slug) &&
    !isValidCmsPage(slug, languageAndCountry);
  const parameterList = params.reduce<Record<string, string>>((acc, curr) => {
    const [key, value] = curr.split(QUERY_PARAMETER_EQUALS);
    if (!key) {
      return acc;
    }
    acc[key] = value ?? '';
    return acc;
  }, {});
  return {
    isIndexedCategoryPage: isCategoryPage && hasOnlyWhitelistedParams(parameterList),
    isUnindexedCategoryPage: isCategoryPage && !hasOnlyWhitelistedParams(parameterList)
  };
};
