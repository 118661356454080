import { Logger } from '@vue-storefront/core';
import { CATEGORY_TREE, CategoryTree } from '~/constants/categoryTree';
import { getPageLinkForSlug } from '~/helpers/routes/getPageInformation';
import { getCategoryPath } from '~/helpers/category/getCategoryPath';

export const buildCategoryPageUrl = (category: string, categoryTree: CategoryTree = CATEGORY_TREE.DEFAULT): string => {
  if (!category) {
    Logger.info('undefined category detected');
    return '';
  }
  const categoryPath = getCategoryPath(category, categoryTree);
  return getPageLinkForSlug(categoryPath);
};
