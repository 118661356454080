import { RELATED_CATEGORIES_KEYS } from '~/constants/relatedCategory';
import { getRelatedTree } from '~/helpers/category/getRelatedTree';
import type { ExtendedCategory, RelatedTree } from '~/types/category/RelatedCategory';

export const getRelatedTrees = (category: ExtendedCategory, languageAndCountry: string): RelatedTree[] => {
  const relatedTrees = [];
  for (const key of RELATED_CATEGORIES_KEYS) {
    const rawCategories = category?.custom?.[key]?.[0]?.referencedResourceSet;
    if (!rawCategories?.length) {
      continue;
    }

    const relatedTree = getRelatedTree(rawCategories, languageAndCountry);
    if (relatedTree) {
      relatedTrees.push(relatedTree);
    }
  }

  return relatedTrees;
};
