






















import { defineComponent, computed } from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';
import { useAbsoluteUrlPage, useInternalCms } from '~/composables';
import { PAGE_DELIVERY_KEYS } from '~/constants/cms';
import DynamicCmsPage from '~/components/pages/DynamicCmsPage.vue';
import Pdp from '~/components/pages/Pdp.vue';
import SearchCategory from '~/components/pages/SearchCategory.vue';
import Tracking from '~/components/pages/Tracking/index.vue';
import { AbsoluteUrlPage } from '~/types/components/AbsoluteUrl/AbsoluteUrlPage';
import { isHomePageSlug } from '~/helpers/routes/getPageInformation';
import CmsPageSkeleton from '~/components/molecules/CmsPageSkeleton.vue';
import { CATEGORY_TREE } from '~/constants/categoryTree';

export default defineComponent({
  name: 'Slug',
  components: {
    DynamicCmsPage,
    Pdp,
    SearchCategory,
    Tracking,
    CmsPageSkeleton
  },
  layout: 'default-full-width',
  middleware: [
    'force-lowercase',
    'home-redirection',
    'slash-redirection',
    'check-search-params',
    'first-page-redirection'
  ],
  setup (_props, { root: { $route } }) {
    const { slug } = $route.params;
    const isHomePage = isHomePageSlug($route.path);
    const absoluteUrlSlug = isHomePage ? '' : slug;
    const {
      loadPage,
      internalCmsContent,
      canShowContent,
      loadingContent
    } = useInternalCms(absoluteUrlSlug || PAGE_DELIVERY_KEYS.HOME.SLUG);

    const { currentPage } = useAbsoluteUrlPage(internalCmsContent);
    const isTrackingPage = computed(() => 'pp_tracking_id' in $route.query);

    onSSR(async () => {
      const searchPromises = [];
      if (currentPage.value === AbsoluteUrlPage.internalCms) {
        searchPromises.push(loadPage());
      }
      await Promise.allSettled(searchPromises);
    });

    return {
      absoluteUrlSlug,
      currentPage,
      AbsoluteUrlPage,
      isTrackingPage,
      slug,
      isHomePage,
      internalCmsContent,
      canShowContent,
      loadingContent,
      CATEGORY_TREE
    };
  }
});

