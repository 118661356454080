var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.faq.length)?_c('section',{staticClass:"faq-section w-full my-8 lg:my-10",attrs:{"id":"faq"}},[_c('ProductAnchorTitle',{staticClass:"mb-4",attrs:{"title":_vm.$t('Frequently asked questions')}}),_vm._v(" "),_vm._l((_vm.faq),function(item,index){return _c('div',{key:index,staticClass:"rounded-lg",class:[
      {
        'invisible h-0 p-0': _vm.hideExcessiveItems(index),
        'p-4 mt-2 border': !_vm.hideExcessiveItems(index)
      }
    ]},[_c('h3',{staticClass:"mb-3 text-base text-EXPONDO-black"},[_vm._v("\n      "+_vm._s(item.question)+"\n    ")]),_vm._v(" "),_c('p',{staticClass:"text-EXPONDO-gray"},[_vm._v("\n      "+_vm._s(item.answer)+"\n    ")])])}),_vm._v(" "),(_vm.faq.length >= _vm.showMaxItems)?_c('button',{staticClass:"w-full mt-4 h-9 flex justify-center items-center bg-EXPONDO-grey-5 rounded-md",class:{ hidden: _vm.faq.length <= _vm.showMaxItems },on:{"click":_vm.toggleShowMore}},[_c('span',{staticClass:"icon-chevron-right text-secondary-100 w-fit before:rotate-90 before:block",class:[
        {
          'before:rotate-90': !_vm.showMore,
          'before:rotate-[-90deg]':_vm.showMore
        }
      ]})]):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }