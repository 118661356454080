

















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ProductCardDelivery',
  props: {
    deliveryDate: {
      type: String,
      required: true
    },
    availabilityDate: {
      type: String,
      required: true
    }
  }
});
