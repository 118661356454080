



































































import { defineComponent } from '@nuxtjs/composition-api';
import { AisPagination } from 'vue-instantsearch';
import { scrollToSection } from '~/helpers/scroll/scrollToSection';

export default defineComponent({
  name: 'Pagination',
  components: {
    AisPagination
  },
  setup() {
    const scrollUpToSearchResults = () => {
      scrollToSection('#layout');
    };

    return {
      scrollUpToSearchResults
    };
  }
});
