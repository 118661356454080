













































































































































































import {
  AisHitsPerPage,
  AisHits,
  AisStats,
  AisSortBy
} from 'vue-instantsearch';
import type { PropType } from '@nuxtjs/composition-api';
import { computed, defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import type { ProductGroupsItemType } from '~/types/cms/ProductGroupsItem';
import Pagination from '@/components/molecules/Search/Pagination.vue';
import { useInternalCms, useUiState, useDisplayCampaignInfo, useIsPage } from '@/composables';
import ProductListing from '~/components/organisms/Search/ProductListing.vue';
import { HITS_PER_PAGE_ITEMS, ALGOLIA_LOADING_STATE, ALGOLIA_STALLED_STATE } from '~/constants/algolia';
import { searchView } from '~/types/search/SearchView';
import SearchFilters from '~/components/organisms/Search/SearchFilters/SearchFilters.vue';
import { getCurrentInstance } from '~/helpers/getCurrentInstance';
import { getCurrentFiltersCount } from '~/helpers/algolia/getCurrentFiltersCount/getCurrentFiltersCount';
import { getAlgoliaSortingConfiguration } from '~/helpers/algolia/getAlgoliaSortingConfiguration';
import { COMPONENTS } from '~/constants/cms';
import { i18nToAlgoliaMainIndex } from '~/helpers/locales/i18nCurrentDetails';
import SearchResultsTopSkeleton
  from '~/components/molecules/SearchResultsSkeletons/SearchResultsTopSkeleton.vue';
import SearchResultsMainSkeleton
  from '~/components/molecules/SearchResultsSkeletons/SearchResultsMainSkeleton.vue';

export default defineComponent({
  name: 'SearchResultsInner',
  components: {
    CategoryBreadcrumbs: () => import(/* webpackChunkName: "CategoryBreadcrumbs" */
      '~/components/molecules/Category/CategoryBreadcrumbs/CategoryBreadcrumbs.vue'),
    AisHitsPerPage,
    AisHits,
    Pagination,
    AisStats,
    AisSortBy,
    SearchFilters,
    ProductListing,
    NoResults: () => import(/* webpackChunkName: "NoResults" */
      '~/components/atoms/NoResults/NoResults.vue'),
    LazyHydrate,
    SearchResultsTopSkeleton,
    SearchResultsMainSkeleton,
    ProductGroupsSlider: () => import(/* webpackChunkName: "ProductGroupsSlider" */
      '~/cms/ProductGroupsSlider/ProductGroupsSlider.vue')
  },
  props: {
    categoryFilter: {
      type: String,
      default: ''
    },
    hits: {
      type: Array,
      required: true
    },
    query: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    productGroupItems: {
      type: Array as PropType<Array<ProductGroupsItemType>>,
      default: () => []
    },
    productGroupItemsTextColor: {
      type: String,
      default: 'text-EXPONDO-black'
    },
    productGroupItemsBackgroundColor: {
      type: String,
      default: 'bg-EXPONDO-grey-5'
    }
  },
  setup(props) {
    const root = getCurrentInstance();
    const { $i18n } = root;
    const { toggleFilterModal } = useUiState();

    const {
      loadComponent: departmentBlockSearch,
      internalCmsContent: departmentBlockContent,
      canShowContent
    } = useInternalCms(COMPONENTS.SHOP_BY_DEPARTMENT.SLUG);

    const { isOnSearchPage } = useIsPage();

    const showSkeletonLoader = computed(() => {
      return props.status === ALGOLIA_STALLED_STATE || props.status === ALGOLIA_LOADING_STATE;
    });

    const {
      showCampaignInfo,
      campaignData
    } = useDisplayCampaignInfo();

    const campaignBanner = computed(() => campaignData.value?.banner || null);
    const campaignTitle = computed(() => campaignData.value?.title || null);

    const isCampaignAvaliable = computed(() => showCampaignInfo.value && campaignBanner.value && campaignTitle.value);

    const onFilterClick = () => {
      toggleFilterModal();
    };
    const listType = ref<string>(searchView.Grid);

    onMounted(() => {
      departmentBlockSearch();
    });

    const shouldShowProductGroupSlider = computed(() => {
      return props.productGroupItems.length > 0;
    });

    return {
      campaignData,
      sortingConfig: getAlgoliaSortingConfiguration($i18n),
      indexName: i18nToAlgoliaMainIndex($i18n),
      HITS_PER_PAGE_ITEMS,
      onFilterClick,
      getCurrentFiltersCount,
      listType,
      departmentBlockContent,
      canShowContent,
      showSkeletonLoader,
      isCampaignAvaliable,
      campaignBanner,
      campaignTitle,
      isOnSearchPage,
      shouldShowProductGroupSlider
    };
  }
});
