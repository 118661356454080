import type { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import { isSecondHandProduct } from '~/helpers/product/getProductInfo/getProductInfo';
import { StringBoolean } from '~/types/StringBoolean';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';

export const getLocalizedSlugsForHreflangs = (product: ProductVariantExtended) => {
  if (!product?.attributesRaw || isSecondHandProduct(product)) {
    return [];
  }
  const eligibleForSelling = product.attributesRaw.filter(
    attribute => attribute.name === PRODUCT_ATTRIBUTES.ELIGIBLE_FOR_SELLING);

  if (!Array.isArray(eligibleForSelling) || !eligibleForSelling[0]?.value) {
    return [];
  }

  const eligibleForSellingItems = eligibleForSelling[0]?.value;

  const localizedEligibleSlugLocales =
    Object.keys(eligibleForSellingItems).filter(itemKey => eligibleForSellingItems[itemKey] === StringBoolean.true);

  return product._slugAllLocales?.filter(localizedEligibleSlugLocale =>
    localizedEligibleSlugLocales.includes(localizedEligibleSlugLocale.locale));
};
