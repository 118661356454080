
































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { AisRefinementList } from 'vue-instantsearch';
import { useVSFContext } from '@vue-storefront/core';
import PricePicker from '~/components/molecules/Search/PricePicker.vue';
import RatingMenu from '~/components/molecules/Search/RatingMenu.vue';
import CheckboxPicker from '~/components/molecules/Search/CheckboxPicker/CheckboxPicker.vue';
import { ALGOLIA_ATTRIBUTE } from '~/constants/algolia';

export default defineComponent({
  name: 'CustomRefinementList',
  components: {
    AisRefinementList,
    PricePicker,
    RatingMenu,
    CheckboxPicker
  },
  props: {
    facet: {
      type: String,
      required: true
    },
    hideTitle: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { i18n } = useVSFContext();

    const refinementListTitle = computed(() => {
      if (props.facet === ALGOLIA_ATTRIBUTE.BRAND) {
        return i18n.t('brand');
      }
      if (props.facet === ALGOLIA_ATTRIBUTE.AVG_RATING) {
        return i18n.t('Ratings');
      }
      if (props.facet === ALGOLIA_ATTRIBUTE.PRICE) {
        return i18n.t('Price');
      }
      return props.facet;
    });
    return {
      refinementListTitle,
      ALGOLIA_ATTRIBUTE
    };
  }
});
