














import { computed, defineComponent, onMounted } from '@nuxtjs/composition-api';
import AlternativeProductDropdown from
  '~/components/molecules/Product/AlternativeProduct/AlternativeProductDropdown.vue';
import { useProductAlternative } from '~/composables';
import { convertAWareToBWare, convertBWareToAWare } from '~/helpers/product/convertBWare';
import { isAWareProduct, isBWareProduct } from '~/helpers/product/productCondition';

export default defineComponent({
  name: 'AlternativeProduct',
  components: {
    AlternativeProductDropdown
  },
  props: {
    sku: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const {
      alternativeProduct,
      search
    } = useProductAlternative(props.sku);

    const isAWare = computed(() => isAWareProduct(props.sku));
    const isBWare = computed(() => isBWareProduct(props.sku));
    const alternativeProductSku = computed(() => {
      if (isAWare.value) {
        return convertAWareToBWare(props.sku);
      }
      if (isBWare.value) {
        return convertBWareToAWare(props.sku);
      }
      return '';
    });

    onMounted(() => {
      if (alternativeProductSku.value) {
        search(alternativeProductSku.value);
      }
    });

    return {
      alternativeProduct,
      isAWare
    };
  }
});

